function initSlider() {
  const slideCount = $('.js-slider').children('li').length;
  const randomStart = Math.floor(Math.random() * slideCount);
  
  $('.js-slider').slick({
    initialSlide: randomStart,
    infinite: true,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
    ]
  });
}


const isSlider = $('.js-slider').length;
if (isSlider) {
  initSlider();
}