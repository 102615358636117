function newsTextLimit() {
  const $window = $(window);
  const $newsList = $('.js-mod-news-list')
  const $text1Limit = $('.js-text-1limit');
  const $text2Limit = $('.js-text-2limit');

  $window.on('load resize', function () {
    $text1Limit.trunk8({
      lines: 1,
      fill: '…',
      tooltip: false,
    });
    $text2Limit.trunk8({
      lines: 2,
      fill: '…',
      tooltip: false,
    });

    $newsList.removeClass('is-hide');
  });
}

newsTextLimit();
