$(function () {
  const $window = $(window);
  const $navBtn = $('.js-nav-btn');
  const $navContent = $('.js-nav-content');
  const breakpoint = 768;
  const duration = 500;
  let navOpen = false;

  function setNav() {
    let windowScroll;
    $navBtn.on('click', function () {
      if (navOpen === false) {
        windowScroll = $window.scrollTop();
        $('body').addClass('is-fixed').css({'top': -windowScroll});
        $navBtn.parent().addClass('is-open');
        $navContent.fadeIn(duration);
        navOpen = true;
      } else {
        $('body').removeClass('is-fixed').css({'top': ''});
        $window.scrollTop(windowScroll);
        $navBtn.parent().removeClass('is-open');
        $navContent.fadeOut(duration);
        navOpen = false;
      }
    });
  }

  function setSpNav() {
    $navContent.hide(duration);
  }

  function setPcNav() {
    $('body').removeClass('is-fixed').css({'top': ''});
    $navBtn.parent().removeClass('is-open');
    $navContent.show(duration);
    navOpen = false;
  }


  function smoothScroll() {
    $('a[href^="#"]').on('click', function () {
      const href = $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top;
      $('html, body').animate({scrollTop: position}, duration);
      return false;
    });
  }

  function getInstagram() {
    $.ajax({
      type: 'GET',
      url: 'https://graph.facebook.com/v3.0/17841413679357333?fields=name%2Cmedia.limit(9)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=EAAZBqZB2r7W0YBAOaUlh6h2bZCZBZB8b46hXb49vd0LY9EMO6WalggvFTYe0lScfy53ZBdNHGmuHdCKeoaAbTvZAc6L3pfhfBm5AFznaMMv6E8qawAGWYjKGzYBkqJ563s2DMDBWgPXCZAJjVfRJMTwExfZCxjSOLu3OkdNezJ3J3OzsnRpt96FbJXP5WdPZAA8rsZD',
      dataType: 'json',
      success: function(json) {
        var html = '';
        var insta = json.media.data;
        for (var i = 0; i < insta.length; i++) {
          html += '<li class="instagram__item"><a href="' + insta[i].permalink + '" class="instagram__link" target="_blank"><img src="' + insta[i].media_url + '"></a></li>';
        }
        $(".instagram__list").append(html);
      },
      error: function() {
        $(".instagram__list").hide();
      }
    });
  }

  function resizeFacebook() {
    const windowWidth = $(window).width();
    let htmlStr = $('.fb-page').html();
    let timer = null;
    $(window).on('resize',function() {
      var resizedWidth = $(window).width();
      if(windowWidth != resizedWidth && resizedWidth > 768) {
        clearTimeout(timer);
        timer = setTimeout(function() {
          $('.fb-page').html(htmlStr);
          window.FB.XFBML.parse();
        }, 500);
      }
    });
  }

  setNav();
  getInstagram();
  resizeFacebook();

  $window.on('load resize', function() {
    const isPC = window.innerWidth >= breakpoint;
    if(isPC) {
      setPcNav();
    } else {
      setSpNav();
    }
  })

  smoothScroll();

});